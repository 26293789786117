import {combineReducers} from "redux";

import {emptyFaq, IFaq} from "@pg-mono/faq";
import {reduceLatestQuery, reduceResponse} from "@pg-mono/request-state";

import {fetchFaqTypes} from "../actions/fetch_faq";

export interface IFaqStore {
    data: IFaq[];
    latestQuery: Record<string, string>;
}

export const faqReducer = combineReducers({
    data: reduceResponse(fetchFaqTypes, emptyFaq),
    latestQuery: reduceLatestQuery(fetchFaqTypes)
});
