import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {WideContent} from "@pg-design/grid";
import {m, mb, ml, mr, mt} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

const chrome = require("../images/chrome_icon.svg");
const edge = require("../images/edge_icon.svg");
const firefox = require("../images/firefox_icon.svg");
const opera = require("../images/opera_icon.svg");
const safari = require("../images/safari_icon.svg");

const supportedBrowsers = [
    {
        name: "Mozilla Firefox",
        url: "https://www.mozilla.org/pl/firefox/new/",
        icon: firefox
    },
    {
        name: "Google Chrome",
        url: "https://www.google.com/intl/pl_pl/chrome/",
        icon: chrome
    },
    {
        name: "Microsoft Edge",
        url: "https://www.microsoft.com/pl-pl/edge",
        icon: edge
    },
    {
        name: "Safari",
        url: "https://support.apple.com/pl_PL/downloads/safari",
        icon: safari
    },
    {
        name: "Opera",
        url: "https://www.opera.com/pl/download",
        icon: opera
    }
];
export const BROWSER_SUPPORT_ID = "rp-browser-support-view";

export const BrowserSupport = () => {
    return (
        <BrowserSupportView id={BROWSER_SUPPORT_ID}>
            <WideContent>
                <Text align="center" as="div" css={mb(3)} variant="headline_0">
                    Twoja przeglądarka nie jest obsługiwana
                </Text>

                <Text align="center" as="p" variant="body_copy_1" mb="1.6rem">
                    Nowa odsłona RynkuPierwotnego została stworzona w oparciu o najnowsze technologie zapewniające szybsze działanie serwisu i maksymalne
                    bezpieczeństwo. Niestety, Twoja aktualna przeglądarka nie wspiera tych technologii.
                </Text>

                <Text align="center" as="p" variant="body_copy_1" css={mb(6)}>
                    Sugerujemy pobranie najnowszej wersji jednej z poniższych wyszukiwarek:
                </Text>

                <div css={iconsHolder}>
                    {supportedBrowsers.map((icon, idx) => (
                        <a href={icon.url} css={browser} rel="nofollow" target="_blank" key={idx}>
                            <img src={icon.icon} alt={icon.name} css={browserIcon} loading="lazy" />
                            <Text align="center" variant="info_txt_1" css={mt(2)}>
                                {icon.name}
                            </Text>
                        </a>
                    ))}
                </div>
            </WideContent>
        </BrowserSupportView>
    );
};

export const BrowserSupportView = styled.div`
    display: none;
    background: #f9f9f9;
    padding: 3.6rem 0 8rem;
    text-align: center;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 10000000;
    top: 0;
    left: 0;

    @media (min-width: ${(props) => props.theme.breakpoints.xs}) {
        padding: 6.4rem 0 8rem;
    }
`;

const iconsHolder = (theme: Theme) => css`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media (min-width: ${theme.breakpoints.xs}) {
        justify-content: center;
    }
`;

const browser = (theme: Theme) => css`
    ${m(2)};

    @media (min-width: ${theme.breakpoints.xs}) {
        ${m(0, 3)};
    }

    &:first-of-type {
        ${ml(0)};
    }

    &:last-of-type {
        ${mr(0)};
    }
`;

const browserIcon = css`
    width: 5.2rem;
    height: 5.2rem;
`;
