import {debounce, isPlainObject} from "@pg-mono/nodash";

import {IDataLayerABVariantEvent} from "../types/IDataLayerABVariantEvent";

const AB_TEST_DATA_LAYER_SYNC_DELAY = 300;

/**
 * Abstraction over dataLayer to reduce the same events in the queue to avoid duplicates in short time
 * e.g. when the same AB test is used in multiple components rendered at the same time
 */
export const ABTestDataLayerQueue = () => {
    const queue: IDataLayerABVariantEvent[] = [];
    const hasReportedExperimentToDataLayer = (id: string) =>
        typeof window !== "undefined" &&
        window.dataLayer.some(
            (item: {exp_variant_string?: string}) => isPlainObject(item) && "exp_variant_string" in item && item.exp_variant_string?.startsWith(id)
        );
    const hasReportedExperimentToQueue = (id: string) => queue.some((item) => item.exp_variant_string.startsWith(id));

    const syncWithDataLayer = debounce(() => {
        if (typeof window === "undefined") {
            return;
        }

        window.dataLayer = window.dataLayer || [];
        queue.forEach(({event, exp_variant_string, pushOnlyOnce}) => {
            if (!pushOnlyOnce || !hasReportedExperimentToDataLayer(exp_variant_string)) {
                window.dataLayer.push({event, exp_variant_string});
            }
        });
        queue.length = 0;
    }, AB_TEST_DATA_LAYER_SYNC_DELAY);

    return {
        queue,
        pushEvent(props: {id: string; variant: string; pushOnlyOnce?: boolean}) {
            if (!hasReportedExperimentToQueue(props.id)) {
                queue.push({event: "experience_impression", exp_variant_string: `${props.id}.${props.variant}`, pushOnlyOnce: props.pushOnlyOnce});
            }
            syncWithDataLayer();
        },
        clear() {
            queue.length = 0;
        }
    };
};
