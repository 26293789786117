import type {ComponentType} from "react";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, useLocation} from "react-router";

import {RouteChangeValidator} from "@pg-mono/data-fetcher";
import {usePrevious} from "@pg-mono/hooks";
import {disableResponseState, ResponseState} from "@pg-mono/response-state";

import {isBrowser} from "../common/app/read_rp_environment_variables";
import {rpDataFetcher} from "../common/app/rp_data_fetcher";
import {IRPStore} from "../common/app/rp_reducer";
import {ErrorViewLayout} from "../common/errors/views/ErrorViewLayout";
import {NotFoundLayout} from "../common/errors/views/NotFoundLayout";
import {initializeAppStoreFavourites} from "../common/favourite/actions/initialize_app_store_favourites";
import {getRouteFromLocation} from "../common/utils/get_route_from_location";
import {clientRpRequestMeta} from "./client_rp_request_meta";

export const withRouteHandler = <T extends {}>(Component: ComponentType<T>) => {
    return (props: T) => {
        const dispatch = useDispatch();
        const location = useLocation();

        const responseState = useSelector((store: IRPStore) => store.responseState);

        const prevLocation = usePrevious(location, location);

        useEffect(() => {
            dispatch(initializeAppStoreFavourites());
        }, []);

        useEffect(() => {
            if (prevLocation.key === location.key) {
                return;
            }

            // Invalidate response state only when location changes so 404 page can stay visible
            if (responseState.state !== ResponseState.DEFAULT) {
                dispatch(disableResponseState());
            }

            if (prevLocation.key !== location.key) {
                const currentRoute = getRouteFromLocation(location);
                const prevRoute = getRouteFromLocation(prevLocation);

                const isRoutechangeValid = RouteChangeValidator.validateChange(currentRoute, prevRoute);
                if (isRoutechangeValid) {
                    dispatch(rpDataFetcher(currentRoute, prevRoute, clientRpRequestMeta));
                }
            }
        }, [location.key]);

        const {pathname, search} = location;

        if (!pathname.endsWith("/")) {
            // This trailing logic is for browser only - SSR has its own.
            // We may consider disabling it for different domains e.g. Google cache
            if (isBrowser) {
                return <Redirect to={`${pathname}/${search}`} />;
            }
        }

        const {state, url} = responseState;

        switch (state) {
            case ResponseState.REDIRECT_301:
            case ResponseState.REDIRECT_302:
                if (url) {
                    return <Redirect to={url} />;
                }
                break;
            case ResponseState.STATE_404:
                return <NotFoundLayout />;
            case ResponseState.STATE_ERROR:
                return <ErrorViewLayout />;
            default:
                return <Component {...props} />;
        }
    };
};
