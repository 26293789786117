import React from "react";
import {Provider} from "react-redux";
import {Global, ThemeProvider} from "@emotion/react";

import {WeglotContextProvider} from "@pg-design/i18n";
import {DSThemeProvider, IThemeRP, themeRP} from "@pg-design/themes";
import {UserDeviceProvider} from "@pg-mono/user-device";

import {RpStore} from "../store/store";
import {globalStylesRp} from "./styles/emotion_global_styles_rp";
import {isDevelopment, weglotApiKey} from "./read_rp_environment_variables";

interface IProps {
    children: React.ReactNode;
    store: RpStore;
    userAgent?: string;
}

declare module "@emotion/react" {
    export interface Theme extends IThemeRP {}
}

export const RpCommonProviders: React.FC<IProps> = (props) => {
    return (
        <Provider store={props.store}>
            <DSThemeProvider>
                <ThemeProvider theme={themeRP}>
                    <Global styles={globalStylesRp} />
                    <UserDeviceProvider userAgent={props.userAgent}>
                        <WeglotContextProvider
                            apiKey={weglotApiKey}
                            excludedTranslationBlocks={[{value: "#offers-panel-region"}, {value: ".bt"}]}
                            isDevelopment={isDevelopment}
                        >
                            {props.children}
                        </WeglotContextProvider>
                    </UserDeviceProvider>
                </ThemeProvider>
            </DSThemeProvider>
        </Provider>
    );
};
