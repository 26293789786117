import {pick} from "@pg-mono/nodash";
import {IOfferFriendlySubFilterGeneralUrlParams, IOfferFriendlySubTypeUrlParams} from "@pg-mono/rp-routes";
import {safelyParsePage} from "@pg-mono/string-utils";

import {trimAggregationFromRegion} from "../../../meta_data/utils/trim_aggregation_from_region";
import {RegionType} from "../../../region/types/RegionType";
import {ILatestQuery, parseQueryParamToInt} from "../../../utils/latest_query";
import {OfferMetaRobots} from "../../constants/offer_meta_robots";
import {IOfferListingSeoRuleParams, offerFilterKeys} from "../../helpers/seo/constants";
import {
    createCanonicalAndRobotsHouseFilterOnlyRule,
    createCanonicalAndRobotsHouseFilterWithOtherFiltersRule
} from "../../helpers/seo/offer_listing_cannical_and_robots_house_filter_rules";
import {
    createCanonicalAndRobotsCommonAreaFilterRule,
    createCanonicalAndRobotsFlatAreaFilterRule,
    createCanonicalAndRobotsHouseAreaFilterRule
} from "../../helpers/seo/offer_listing_canonical_and_robots_area_rules";
import {getOfferListingSeoCanonicalByRules, getOfferListingSeoRobotsByRules} from "../../helpers/seo/offer_listing_canonical_and_robots_helpers";
import {
    createCanonicalAndRobotsCommonRoomsFilterRule,
    createCanonicalAndRobotsFlatRoomsFilterRule,
    createCanonicalAndRobotsHouseRoomsFilterRule
} from "../../helpers/seo/offer_listing_canonical_and_robots_rooms_rules";
import {
    createCanonicalAndRobotsCommercialAndMixedTypeFilterRule,
    createCanonicalPageRule,
    createGeneralSeoRobotsRule,
    createRobotsMixedTypeForCustomFilterRule,
    createSpecialListingCanonicalRule,
    createSpecialListingsRobotsRule
} from "../../helpers/seo/offer_listing_canonical_and_robots_rules";
import {
    createRobotsPriceCommercialRule,
    createRobotsPriceFurtherPagesRule,
    createRobotsPriceGeneralRule,
    createRobotsPriceToFilterRule
} from "../../helpers/seo/offer_listing_robots_price_rules";
import {PRIVILEGE_OFFER_QUERY_PARAM_NAME} from "../constants/offer_list";

export interface GetOfferListRobotsCanonicalMetaParams {
    soonFinishedOffersCount: number;
    soldOffersCount: number;
    offersCount: number;
    latestQuery: ILatestQuery;
    hasSeoDescription: boolean;
    regions:
        | {
              id: number;
              type: RegionType;
              slug: string;
          }[]
        | undefined;
    ctx: {
        route: {
            pathname: string;
            query: Record<string, string | string[]>;
        };
        match: {
            params: Partial<IOfferFriendlySubFilterGeneralUrlParams & IOfferFriendlySubTypeUrlParams>;
        };
    };
}

export const getOfferListsRobotsCanonicalMeta = (params: GetOfferListRobotsCanonicalMetaParams) => {
    const {ctx, latestQuery, offersCount, hasSeoDescription, soldOffersCount, soonFinishedOffersCount, regions} = params;
    const {offerListSubFilter} = ctx.match.params;
    const {page, ...restQuery} = ctx.route.query;
    const parsedPage = safelyParsePage(page);

    const filterData = pick(latestQuery, offerFilterKeys);

    const commonSeoParams = {
        offerType: parseQueryParamToInt(latestQuery.type),
        regions,
        filters: filterData,
        sort: latestQuery.sort,
        page: parsedPage || undefined
    };

    const commonCanonicalAndRobotsRules = getCanonicalAndRobotsRules(commonSeoParams);

    const offerListSubFilterRobotsAndCanonicalRules = getCanonicalAndRobotsRulesForHouseFilter(commonSeoParams, offersCount, offerListSubFilter);

    const robots =
        getOfferListingSeoRobotsByRules([
            ...offerListSubFilterRobotsAndCanonicalRules,
            ...commonCanonicalAndRobotsRules,
            createGeneralSeoRobotsRule({
                page: page ? parsedPage : undefined,
                seoDescriptionExists: hasSeoDescription,
                sortParamExists: Boolean(restQuery?.sort),
                routeQuery: ctx.route.query,
                soonFinishedOffersCount,
                soldOffersCount,
                offersCount
            }),
            createSpecialListingsRobotsRule({...commonSeoParams, routePathname: ctx.route.pathname}),
            createRobotsMixedTypeForCustomFilterRule({offerType: commonSeoParams.offerType, query: ctx.route.query}),
            {
                condition: () => Boolean(restQuery && restQuery[PRIVILEGE_OFFER_QUERY_PARAM_NAME]),
                robots: OfferMetaRobots.NOINDEX_FOLLOW
            }
        ]) || OfferMetaRobots.INDEX_FOLLOW;

    const customCanonicalPath = getOfferListingSeoCanonicalByRules([
        ...offerListSubFilterRobotsAndCanonicalRules,
        createCanonicalPageRule(commonSeoParams),
        createSpecialListingCanonicalRule({
            ...commonSeoParams,
            routePathname: ctx.route.pathname
        }),
        ...commonCanonicalAndRobotsRules
    ]);

    return {
        robots,
        canonical: trimAggregationFromRegion(customCanonicalPath || ctx.route.pathname)
    };
};

export const getCanonicalAndRobotsRules = (seoParams: IOfferListingSeoRuleParams) => [
    createRobotsPriceCommercialRule(seoParams),
    createRobotsPriceFurtherPagesRule(seoParams),
    createRobotsPriceToFilterRule(seoParams),
    createCanonicalAndRobotsCommonRoomsFilterRule(seoParams),
    createCanonicalAndRobotsCommonAreaFilterRule(seoParams),
    createCanonicalAndRobotsFlatRoomsFilterRule(seoParams),
    createCanonicalAndRobotsFlatAreaFilterRule(seoParams),
    createCanonicalAndRobotsHouseRoomsFilterRule(seoParams),
    createCanonicalAndRobotsHouseAreaFilterRule(seoParams),
    createCanonicalAndRobotsCommercialAndMixedTypeFilterRule(seoParams),
    createRobotsPriceGeneralRule(seoParams)
];

const getCanonicalAndRobotsRulesForHouseFilter = (seoParams: IOfferListingSeoRuleParams, offerCount: number, offerListSubFilter?: string) => [
    createCanonicalAndRobotsHouseFilterOnlyRule(seoParams, offerCount, offerListSubFilter),
    createCanonicalAndRobotsHouseFilterWithOtherFiltersRule(seoParams, offerCount, offerListSubFilter)
];
