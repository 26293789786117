import {abTestingReducer, IABTestingStore} from "@pg-mono/ab-testing";
import {IFavouriteStore} from "@pg-mono/favourites";
import {IResponseStateStore, responseStateReducer} from "@pg-mono/response-state";

import {applicationReducer, IApplicationStore} from "../application/reducers/application_reducer";
import {IMarketplaceStore, marketplaceReducer} from "../architect_offers/reducers/marketplace_reducer";
import {articleReducer, IArticleStore} from "../article/reducers/article_reducer";
import {IIsAuthenticatedStore, isAuthenticatedReducer} from "../auth/reducers/is_authenticated_reducer";
import {faqReducer, IFaqStore} from "../faq/reducers/faq_reducer";
import {favouriteReducer} from "../favourite/reducers/favourite_reducer";
import {homepageReducer, IHomepageStore} from "../homepage/reducers/homepage_reducer";
import {IInvestmentOffersStore, investmentOffersReducer} from "../investment_offer/reducers/investment_offer_reducer";
import {ILeadFormState, leadFormReducer} from "../lead/reducers/lead_form_reducer";
import {ISentLeadState, sentLeadReducer} from "../lead/reducers/sent_lead_reducer";
import {IMapsStore, mapsReducer} from "../maps/reducers/maps_reducer";
import {IMetaDataStore, metaDataReducer} from "../meta_data/reducers/meta_data_reducer";
import {IMyOffersListStore, myOffersReducer} from "../my_offers/reducers/my_offers_reducer";
import {backToUrlReducer, IBackToUrlStore} from "../navigation/reducers/back_to_url_reducer";
import {footerReducer, IFooterStore} from "../navigation/reducers/footer_reducer";
import {INavigationStore, navigationSlice} from "../navigation/state/navigation_slice";
import {INewsletterStore, newsletterReducer} from "../newsletter/reducers/newsletter_reducer";
import {IOfferDetailStore, offerDetailReducer} from "../offer/detail/reducers/offer_detail_reducer";
import {IOffersNearbyStore, offersNearbyReducer} from "../offer/detail/reducers/offers_nearby_reducer";
import {INotificationsSubscriptionModalStore, notificationsSubscriptionModalReducer} from "../offer/list/reducers/notifications_subscription_modal_reducer";
import {IOfferListStore, offerListReducer} from "../offer/list/reducers/offer_list_reducer";
import {IOfferListMapStore, offerListMapReducer} from "../offer/map/reducers/map_reducer";
import {offerListingSlice} from "../offer/state/offer_listing_slice";
import {IOurOfferStore, ourOfferReducer} from "../our_offer/reducers/our_offer_reducer";
import {IPartnersListStore, partnersListReducer} from "../partners/reducers/partners_list_reducer";
import {IPromotionListListStore, promotionListListReducer} from "../promotion/reducers/promotion_list_reducer";
import {IPropertyDetailStore, propertyDetailReducer} from "../property/reducers/property_reducer";
import {IPropertyConfiguratorStore, propertyConfiguratorStore} from "../property_configurator/reducers/property_configurator_reducer";
import {IReportStore, reportReducer} from "../report/price/reducers/report_reducer";
import {ISearchStore, searchReducer} from "../search/reducers/search_reducer";
import {ISiteMapStore, siteMapReducer} from "../site_map/reducers/site_map_reducer";
import {ISmartAdsStore, smartAdsReducer} from "../smart_ad_server/reducer/smart_ads_reducer";
import {googleTagManagerReducer, IGoogleTagManagerStore} from "../tracking/google_tag_manager/reducers/google_tag_manager_reducer";
import {IUiStore, uiReducer} from "../ui/reducers/ui_reducer";
import {IUserStore, userReducer} from "../user/reducers/user_reducer";
import {IVariablesStore, variablesReducer} from "../variables/reducers/variables_reducer";
import {IVendorStore, vendorReducer} from "../vendor/reducers/vendor_reducer";
import {IViewTypeStore, viewTypeReducer} from "../view_type/view_type_reducer";
import {globalsReducer, IGlobalsStore} from "./reducers/globals_reducer";

export interface IRPStore extends IIsAuthenticatedStore {
    // rest - please keep alphabetical order
    abTesting: IABTestingStore;
    application: IApplicationStore;
    article: IArticleStore;
    backToUrl: IBackToUrlStore;
    faq: IFaqStore;
    favourite: IFavouriteStore;
    propertyConfigurator: IPropertyConfiguratorStore;
    footer: IFooterStore;
    globals: IGlobalsStore;
    googleTagManager: IGoogleTagManagerStore;
    homepage: IHomepageStore;
    investmentOffer: IInvestmentOffersStore;
    leadForm: ILeadFormState;
    maps: IMapsStore;
    marketplace: IMarketplaceStore;
    metaData: IMetaDataStore;
    myOffers: IMyOffersListStore;
    navigation: INavigationStore;
    newsletter: INewsletterStore;
    notificationsSubscriptionModal: INotificationsSubscriptionModalStore;
    offerDetail: IOfferDetailStore;
    offerList: IOfferListStore;
    smartAds: ISmartAdsStore;
    offersNearby: IOffersNearbyStore;
    ourOffer: IOurOfferStore;
    offerListMap: IOfferListMapStore;
    partnersList: IPartnersListStore;
    promotionList: IPromotionListListStore;
    property: IPropertyDetailStore;
    report: IReportStore;
    responseState: IResponseStateStore;
    search: ISearchStore;
    sentLead: ISentLeadState;
    siteMap: ISiteMapStore;
    ui: IUiStore;
    user: IUserStore;
    variables: IVariablesStore;
    vendor: IVendorStore;
    viewType: IViewTypeStore;
}

export const rpReducerObj = {
    // Please keep alphabetical order
    abTesting: abTestingReducer,
    article: articleReducer,
    application: applicationReducer,
    backToUrl: backToUrlReducer,
    faq: faqReducer,
    favourite: favouriteReducer,
    footer: footerReducer,
    globals: globalsReducer,
    googleTagManager: googleTagManagerReducer,
    homepage: homepageReducer,
    isAuthenticated: isAuthenticatedReducer, // IIsAuthenticatedStore
    investmentOffer: investmentOffersReducer,
    leadForm: leadFormReducer,
    maps: mapsReducer,
    marketplace: marketplaceReducer,
    metaData: metaDataReducer,
    myOffers: myOffersReducer,
    newsletter: newsletterReducer,
    navigation: navigationSlice.reducer,
    notificationsSubscriptionModal: notificationsSubscriptionModalReducer,
    offerDetail: offerDetailReducer,
    offerList: offerListReducer,
    offerListing: offerListingSlice.reducer,
    offerListMap: offerListMapReducer,
    offersNearby: offersNearbyReducer,
    partnersList: partnersListReducer,
    ourOffer: ourOfferReducer,
    promotionList: promotionListListReducer,
    property: propertyDetailReducer,
    propertyConfigurator: propertyConfiguratorStore,
    report: reportReducer,
    responseState: responseStateReducer,
    search: searchReducer,
    sentLead: sentLeadReducer,
    siteMap: siteMapReducer,
    smartAds: smartAdsReducer,
    ui: uiReducer,
    user: userReducer,
    variables: variablesReducer,
    vendor: vendorReducer,
    viewType: viewTypeReducer
};
