import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {Country} from "../../region/types/Country";
import {RegionType} from "../../region/types/RegionType";
import {IVendorRegion} from "./fetch_vendor_list_region";

const FETCH_VENDOR_NEARBY_REGIONS_VENDORS_PREFIX = "offer_list/FETCH_VENDOR_NEARBY__REGIONS_VENDORS";
export const fetchVendorNearbyRegionsVendorsTypes = createRequestActionTypes(FETCH_VENDOR_NEARBY_REGIONS_VENDORS_PREFIX);

interface IVendorNearbyRegionsResponse {
    results: IVendorsNearbyRegion[];
}

export interface IVendorsNearbyRegion {
    name: string;
    slug: string;
    id: number;
    stats: {
        properties_all_count_for_sale: number;
        properties_flats_count_for_sale: number;
        properties_houses_count_for_sale: number;
        properties_commercials_count_for_sale: number;
        vendors_count_for_sale: number;
    };
    type: RegionType;
}

const fetchVendorNearbyRegionVendors =
    (actionType: RequestActionTypes) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
        const regions = getState().vendor.list.region;
        if (!regions || regions.length === 0) {
            return false;
        }

        const nearbyRegionsListApiLink = apiV2ListLink.region.list(Scenario.REGION_LIST);
        const url = appendQueryString(nearbyRegionsListApiLink, {
            ...getNearbyRegionVendorsFetchParams(regions),
            country: Country.POLAND
        });

        return getRequest(ctx.meta, url)
            .then((res: IVendorNearbyRegionsResponse) => {
                dispatch({type: actionType.success, result: res});
                return res;
            })
            .catch(
                catch400((error) => {
                    dispatch({type: actionType.error, error: error.appError});
                })
            );
    };

const getNearbyRegionVendorsFetchParams = (regions?: IVendorRegion[] | null) => {
    if (!regions || regions.length === 0) {
        return {};
    }

    if (regions[0].type === RegionType.VOIVODESHIP) {
        return {nearby_regions: regions[0].id, parent_type: RegionType.VOIVODESHIP, type: RegionType.CITY};
    }

    return {nearby_regions: regions[0].id};
};

export const fetchVendorNearbyRegionVendorsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => {
    return fetchVendorNearbyRegionVendors(fetchVendorNearbyRegionsVendorsTypes)(ctx);
};
