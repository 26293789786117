import React, {useEffect} from "react";
import {useState} from "react";
import {css, Theme} from "@emotion/react";
import styled, {Interpolation} from "@emotion/styled";

export interface IProps {
    children: React.ReactNode;
    title: JSX.Element;
    panelArrowIcon: JSX.Element;
    open?: boolean;
    alwaysOpen?: boolean;
    disableOnDesktop?: boolean;
    disableOverflowHiddenOnOpen?: boolean;
    headerCss?: Interpolation<Theme>;
    iconWrapperCss?: Interpolation<Theme>;
    bodyWrapperCss?: Interpolation<Theme>;
    className?: string;
}

export const PanelExpandable: React.FC<IProps> = (props) => {
    const openState = props.alwaysOpen || props.open;
    const disableOnDesktop = typeof props.disableOnDesktop === "boolean" ? props.disableOnDesktop : true;

    const [isOpen, setIsOpen] = useState(openState);
    const [isAnimationInProgress, setIsAnimationInProgress] = useState(false);

    useEffect(() => {
        if (isAnimationInProgress) {
            setTimeout(() => {
                setIsAnimationInProgress(false);
            }, 200);
        }
    }, [isAnimationInProgress]);

    const onClick = () => {
        if (!props.alwaysOpen) {
            setIsAnimationInProgress(true);
            setIsOpen(!isOpen);
        }
    };

    return (
        <PanelExpandableWrapper disableOnDesktop={disableOnDesktop} className={props.className}>
            <PanelExpandableHeader disableOnDesktop={disableOnDesktop} onClick={onClick} css={props.headerCss}>
                {props.title}
                {!props.alwaysOpen && (
                    <PanelExpandableIconWrapper disableOnDesktop={disableOnDesktop} isOpen={isOpen} css={props.iconWrapperCss}>
                        {props.panelArrowIcon}
                    </PanelExpandableIconWrapper>
                )}
            </PanelExpandableHeader>
            <PanelExpandableBody
                isOverflowHidden={!props.disableOverflowHiddenOnOpen || !isOpen || isAnimationInProgress}
                disableOnDesktop={disableOnDesktop}
                isOpen={isOpen}
                css={props.bodyWrapperCss}
            >
                {props.children}
            </PanelExpandableBody>
        </PanelExpandableWrapper>
    );
};

interface IThemeProps {
    theme?: Theme;
    isOpen?: boolean;
    disableOnDesktop?: boolean;
}

const PanelExpandableWrapper = styled.div<IThemeProps>`
    width: 100%;

    ${({disableOnDesktop, theme}) =>
        disableOnDesktop &&
        css`
            @media (min-width: ${theme?.breakpoints?.md ?? "1024px"}) {
                border-bottom: none;
            }
        `};
`;

const PanelExpandableHeader = styled.div<IThemeProps>`
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    padding: 1.8rem 0;

    @media (min-width: ${(props) => props.theme?.breakpoints?.md ?? "1024px"}) {
        padding: 0 0 1.8rem 0;
    }
`;

const PanelExpandableIconWrapper = styled.div<IThemeProps>`
    display: flex;
    align-items: center;
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "")};
    margin-right: 1rem;

    ${({disableOnDesktop, theme}) =>
        disableOnDesktop &&
        css`
            @media (min-width: ${theme?.breakpoints?.md ?? "1024px"}) {
                display: none;
            }
        `};
`;

const PanelExpandableBody = styled.div<IThemeProps & {isOverflowHidden: boolean}>`
    font-size: 1.2rem;
    display: flex;
    padding: 0 1rem;

    max-height: ${(props) => (props.isOpen ? "900px" : "0")};
    ${({isOverflowHidden}) => isOverflowHidden && "overflow: hidden;"};
    flex-direction: column;

    ${({disableOnDesktop, theme}) =>
        disableOnDesktop &&
        css`
            @media (min-width: ${theme?.breakpoints?.sm ?? "768px"}) {
                flex-direction: row;
            }

            @media (min-width: ${theme?.breakpoints?.md ?? "1024px"}) {
                padding: 0;
                max-height: initial;
                overflow: initial;
            }
        `};

    transition: max-height 0.2s ease-in-out;
`;
