import React from "react";
import {useRef, useState} from "react";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button";
import {flexDirection, w100} from "@pg-design/helpers-css";
import {form, IFormProps} from "@pg-mono/form";

import {useClickOutside} from "../../hooks/handle_click_outside";
import {DropdownToOpen} from "../constants/DropdownToOpen";
import {Filter} from "./filters/SearchFilter";
import {OfferDistanceSwitch} from "./OfferDistanceSwitch";

interface IOwnProps extends IFormProps<ISearchFiltersFormValues> {
    setDesktopTypeBlendActive: (isOpen: boolean) => void;
    defaultValue?: number;
}

export interface ISearchFiltersFormValues {
    distance?: number;
}

interface IProps extends IOwnProps {
    isMobileDropdown?: boolean;
    onFilterOpen?: () => void;
}

const SearchDistanceFilterC = (props: IProps) => {
    const filtersWrapperRef = useRef<HTMLElement | null>(null);
    const filtersWrapperRefHandle = (elem: HTMLElement | null) => {
        filtersWrapperRef.current = elem;
    };

    const [isDistanceFilterOpen, setDistanceFilterOpen] = useState(false);

    useClickOutside(filtersWrapperRef, () => {
        setDistanceFilterOpen(false);
        props.setDesktopTypeBlendActive(false);
    });

    const setOpen = (dropdownToOpen: DropdownToOpen | null) => {
        if (dropdownToOpen) {
            props.onFilterOpen?.();
        }
        setDistanceFilterOpen(!!dropdownToOpen);
        props.setDesktopTypeBlendActive(!!dropdownToOpen);
    };

    const onSubmit = () => {
        if (props.onSubmit) {
            props.onSubmit();
        }
    };

    const distanceProps = props.getFieldProps("distance");
    const distanceValue = typeof distanceProps.value !== "number" ? props.defaultValue || 0 : distanceProps.value;

    useClickOutside(filtersWrapperRef, () => setDistanceFilterOpen(false));

    return (
        <div css={[filterHolder]} ref={filtersWrapperRefHandle}>
            <Filter
                label="Szukaj w promieniu"
                id="distanceFilter"
                {...distanceProps}
                value={distanceValue}
                getLabel={() => `+${distanceValue} km`}
                labelId="distance-filter-field"
                errorOnBottom
                isDropdownOpened={isDistanceFilterOpen}
                setDropdownOpen={setOpen}
                dropdownName={DropdownToOpen.DISTANCE}
                isMobileWidth={false}
                disableClearAction
                isMobileDropdown={props.isMobileDropdown}
                rightDropdown
                onChange={(name, value) => {
                    if (typeof value === "number") {
                        distanceProps.onChange(name, value);
                    }
                }}
                onAfterChange={(name, value) => {
                    if (typeof value === "number") {
                        distanceProps.onAfterChange(name, value);
                    }
                }}
            >
                {(props) => (
                    <>
                        <OfferDistanceSwitch {...props} css={flexDirection("column")} />
                        <Button
                            variant="filled_primary"
                            onClick={() => {
                                props.onChange(props.name, props.value);
                                props.onAfterChange(props.name, props.value);
                                setOpen(null);
                                onSubmit();
                            }}
                            size="small"
                            css={[w100]}
                        >
                            Wybierz
                        </Button>
                    </>
                )}
            </Filter>
        </div>
    );
};

export const SearchDistanceFilter = form<ISearchFiltersFormValues, IProps>(SearchDistanceFilterC);

const filterHolder = css`
    display: flex;
    flex-direction: row;
    align-items: start;
    width: 100%;
    border-right: 0 none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0 0;

    div:nth-of-type(3) {
        margin-right: 0;
    }

    > div {
        margin-right: 0;
    }
`;
