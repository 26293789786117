import React, {forwardRef, ReactNode, useRef} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {RadioCheckedIcon, RadioIcon} from "@pg-design/icons";

//  Types
export type IRadioProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "onChange" | "checked" | "aria-labelledby"> & {
    name: string;
    value: IRadioValue;
    id: string;
    isActive?: boolean;
} & ILabelScenarios &
    IReadWriteScenarios;

type IRadioValue = string | number | boolean;

//  Label scenarios - provide aria-labelledby if you're not providing a labelContent
type ILabelScenarios = INoLabelScenario | ILabelProvidedScenario;

type INoLabelScenario = {
    labelContent?: never;
    "aria-labelledby": string;
};

type ILabelProvidedScenario = {
    labelContent: ReactNode | string;
    "aria-labelledby"?: never;
};

// RW scenarios - provide onChange when you don't provide readOnly
type IReadWriteScenarios = IEditableScenario | IReadOnlyScenario;

type IEditableScenario = {
    readOnly?: never;
    onChange: (name: string, isActive: boolean, value: IRadioValue) => void;
};

type IReadOnlyScenario = {
    onChange?: Function | never;
    readOnly: true;
};

// Component
export const Radio = forwardRef<HTMLInputElement, IRadioProps>((props, forwardedRef) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {isActive, onChange, autoFocus, readOnly, labelContent, id, ...inputProps} = props;
    const radioRef = forwardedRef ? useRef<HTMLInputElement | null>(null) : (forwardedRef as unknown as React.MutableRefObject<HTMLInputElement>);

    return (
        <ComponentContainer readOnly={readOnly}>
            <div css={radioWrapper}>
                <HiddenInput
                    {...inputProps}
                    type="radio"
                    checked={isActive}
                    onChange={({target: {checked, value, name}}) => {
                        if (!readOnly && onChange) {
                            onChange(name, checked, value);
                        }
                    }}
                    id={id}
                    ref={radioRef}
                />
                <div css={radioWrapStyle}>
                    <div css={radioContainerStyle}>
                        {isActive ? (
                            <RadioActiveMark readOnly={readOnly} isActive={isActive}>
                                <RadioCheckedIcon fill="#02D054" />
                            </RadioActiveMark>
                        ) : (
                            <RadioFrame readOnly={readOnly} className={radioFrameClassName}>
                                <RadioIcon fill="transparent" />
                            </RadioFrame>
                        )}
                    </div>
                </div>
            </div>
            {labelContent && (
                <label
                    css={labelContainer}
                    onClick={() => {
                        if (!readOnly && onChange && radioRef?.current) {
                            const {name, value, checked} = radioRef.current;
                            onChange(name, !checked, value);
                        }
                    }}
                    htmlFor={id}
                >
                    {labelContent}
                </label>
            )}
        </ComponentContainer>
    );
});

const radioFrameClassName = "ds-radio-frame";

// Styles
const ComponentContainer = styled.div<{readOnly?: boolean}>`
    display: inline-flex;
    align-items: center;

    ${(props) =>
        !props.readOnly &&
        css`
            &:hover svg.${radioFrameClassName} {
                fill: black;
            }
        `}
`;

const labelContainer = css`
    cursor: pointer;
    margin-left: 0.8rem;
`;

const radioWrapper = css`
    position: relative;
    cursor: pointer;
`;

const HiddenInput = styled.input`
    cursor: inherit;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 20;

    & ~ div {
        transition: ${({theme}) => `outline, ${theme.transition.timingFunction}, ${theme.transition.duration}`};
        outline: 0.2rem solid transparent;
        border-radius: 4rem;
    }

    &:focus-visible ~ div {
        outline: ${({theme}) => `0.2rem solid ${theme.colors.info}`};
    }
`;

const radioWrapStyle = css`
    border-radius: 0.4rem;
`;

const radioContainerStyle = css`
    width: 2.4rem;
    height: 2.4rem;
`;

const RadioFrame = styled.div<{readOnly?: boolean}>`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

    svg {
        width: 100%;
        height: 100%;
        fill: ${({theme, readOnly}) => (readOnly ? theme.colors.gray["300"] : theme.colors.gray["700"])};
        transition: ${({theme}) => `fill, ${theme.transition.timingFunction}, ${theme.transition.duration}`};
    }
`;

const RadioActiveMark = styled.div<{readOnly?: boolean; isActive?: boolean}>`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    opacity: ${({isActive}) => (isActive ? 100 : 0)};
    transition: ${({theme}) => `opacity, ${theme.transition.timingFunction}, ${theme.transition.duration}`};

    svg {
        width: 100%;
        height: 100%;
        transition: ${({theme}) => `fill, ${theme.transition.timingFunction}, ${theme.transition.duration}`};
        fill: ${({theme, readOnly}) => (readOnly ? theme.colors.gray["300"] : "#02D054")};
    }
`;
