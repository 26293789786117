import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {emptyFaq, IFaq} from "@pg-mono/faq";
import {isEqual} from "@pg-mono/nodash";
import {appendQueryString, getRequest, IQueryValue} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink} from "@pg-mono/rp-api-routes";

import {rpCommonUrl} from "../../app/read_rp_environment_variables";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";

export interface IFaqFetchResponse {
    page: number;
    count: number;
    next: string | null;
    previous: string | null;
    page_size: number;
    results: IFaq[];
}

export const FETCH_FAQ_PREFIX = "faq/FETCH_FAQ";
export const fetchFaqTypes = createRequestActionTypes(FETCH_FAQ_PREFIX);

export const fetchFaqListAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch, getState: () => IRPStore) => {
    const query: Record<string, IQueryValue> = {
        url: `${rpCommonUrl}${ctx.route.pathname}`
    };

    const faq = getState().faq;
    if (isEqual(faq.latestQuery, query)) {
        // prevent fetching the same data
        return Promise.resolve(faq.data);
    }

    dispatch({type: fetchFaqTypes.start, latestQuery: query});
    const url = decodeURIComponent(appendQueryString(apiV2ListLink.faq.list(null), query));
    return getRequest(ctx.meta, url)
        .then((json: IFaqFetchResponse) => {
            const result = json.results || emptyFaq;
            dispatch({type: fetchFaqTypes.success, result});
            return json;
        })
        .catch(() => {
            return emptyFaq;
        });
};
