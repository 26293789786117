import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {IGetOfferDetailPoiResponse} from "../../../maps/api/get_offer_detail_poi";
import {PoiType} from "../../../maps/utils/PoiType";

const FETCH_OFFER_DETAIL_TRANSPORT_POI = "overpass/FETCH_OFFER_DETAIL_TRANSPORT_POI";
export const fetchOfferDetailTransportPoi = createRequestActionTypes(FETCH_OFFER_DETAIL_TRANSPORT_POI);

export const fetchOfferTransportPoiAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    // do not use offerId from url params, since it may be id of incorrect investment stage
    const offerId = ctx.prevResult.offer.id;

    const url = appendQueryString(apiV2Link.offer.detail(Scenario.OFFER_DETAIL_POIS, {offerId}), {
        poi_type: [PoiType.TRANSPORT]
    });

    return getRequest(ctx.meta, url).then((res: IGetOfferDetailPoiResponse) => {
        dispatch({type: fetchOfferDetailTransportPoi.success, result: res});
        return res;
    });
};
