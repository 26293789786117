import {css} from "@emotion/react";

import {modalStyles} from "@pg-design/modal";
import {fontFace} from "@pg-design/styles";

import {BROWSER_SUPPORT_ID} from "../components/BrowserSupport";
import {tippyPopoverStyles} from "./tippy_popover_styles";

const getFontPath = (variant: string): string => `/static-rp/fonts/${variant}/`;

const primaryFontVariant = "poppins";
const primaryFontPath = getFontPath(primaryFontVariant);

const secondaryFontVariant = "playfair_display";
const secondaryFontPath = getFontPath(secondaryFontVariant);

export const globalStylesRp = css`
    ${fontFace(primaryFontPath, primaryFontVariant, `${primaryFontVariant}_light`, 300)};
    ${fontFace(primaryFontPath, primaryFontVariant, `${primaryFontVariant}_regular`, 400)};
    ${fontFace(primaryFontPath, primaryFontVariant, `${primaryFontVariant}_medium`, 500)};
    ${fontFace(primaryFontPath, primaryFontVariant, `${primaryFontVariant}_semibold`, 600)};
    ${fontFace(primaryFontPath, primaryFontVariant, `${primaryFontVariant}_bold`, 700)};

    ${fontFace(secondaryFontPath, secondaryFontVariant, `${secondaryFontVariant}_regular`, 400)};
    ${fontFace(secondaryFontPath, secondaryFontVariant, `${secondaryFontVariant}_bold`, 700)};

    ${tippyPopoverStyles}
    ${modalStyles};

    a:hover,
    a:focus {
        outline: 0;
    }

    .mobile-search-offset {
        padding-bottom: 6.2rem;
    }

    .mobile-contact-bar-offset {
        padding-bottom: 6rem;
    }

    /**
    based on weather "align-items" is supported by browser or not we show/hide browser upgrade message to a user.
    Because "align-items" has the closest correlation to expected browsers compatibility it is being used as indicator.
     */
    @supports not (align-items: center) {
        body {
            overflow: hidden;
        }
        #${BROWSER_SUPPORT_ID} {
            display: block !important;
        }
    }

    /*
    Code below should work in case of IE10+, @supports doesn't work there, in order to show BrowserSupportView it needs to be set with @media.   
    */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        body {
            overflow: hidden;
        }
        #${BROWSER_SUPPORT_ID} {
            display: block !important;
        }
    }
`;
