/**
 * While clearing our own cache, we append a cache miss header to api requests.
 * @param headers
 * @param api - Redux toolkit api
 */
import {IRootState} from "../../store/store";

export const appendCacheMissHeader = (headers: Headers, api: {getState: () => unknown}) => {
    // RTK/Redux team claims that using app state inside actions is considered an antipattern because this makes these functions "unpure". They type api.getState as () => "unknown"
    // We want to modify api request headers, and not modify api response, casting seems innocent enough.
    const state = api.getState() as IRootState;

    const shouldMissCache = !!state?.headers.requestHeaders["x-cache-force-miss"];

    if (shouldMissCache) {
        headers.set("x-cache-force-miss", "1");
    }
};
