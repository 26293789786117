import {combineReducers} from "redux";

import {headersSlice} from "../app/api/headers_api";
import {mpApi} from "../app/api/mp_api";
import {rpApi} from "../app/api/rp_api";
import {rpReducerObj} from "../app/rp_reducer";
import {modularHouseOfferGalleryModalSlice} from "../modular_houses/store/modular_house_offer_gallery_modal";
import {modularHouseOfferLeadModalSlice} from "../modular_houses/store/modular_house_offer_lead_modal_slice";
import {notificationsSlice} from "../notifications/state/notifications_slice";
import {yslabApi} from "../offer/detail/planner3D/components/api/yslab_api";
import {offerModalsSlice} from "../offer/state/offer_modals_slice";

export const rootReducer = combineReducers({
    ...rpReducerObj,
    //  Api
    [rpApi.reducerPath]: rpApi.reducer,
    [mpApi.reducerPath]: mpApi.reducer,
    [yslabApi.reducerPath]: yslabApi.reducer,
    //  Slices
    [headersSlice.name]: headersSlice.reducer,
    [notificationsSlice.name]: notificationsSlice.reducer,
    [offerModalsSlice.name]: offerModalsSlice.reducer,
    [modularHouseOfferLeadModalSlice.name]: modularHouseOfferLeadModalSlice.reducer,
    [modularHouseOfferGalleryModalSlice.name]: modularHouseOfferGalleryModalSlice.reducer
});

export const apiMiddlewares = [rpApi.middleware, mpApi.middleware, yslabApi.middleware];
