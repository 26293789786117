import {useDispatch} from "react-redux";

import {IFooterMenuData} from "@pg-design/footer";
import {rpAppLink} from "@pg-mono/rp-routes";

import {InvestmentOfferTypeSlug} from "../../investment_offer/constants/investment_offer_constants";
import {addNotification} from "../../notifications/state/notifications_slice";
import {buildFriendlyOfferListLink} from "../../offer/helpers/friendly_offer_list/build_friendly_offer_list_link";
import {buildFriendlyPromotionSlug} from "../../promotion/helpers/build_friendly_promotion_slug";
import {PromotionType} from "../../promotion/helpers/PromotionType";
import {opportunityListPGLink, propertyGroupLink} from "../constants/links";

export function useFooterNavData(): IFooterMenuData {
    const dispatch = useDispatch();

    const openCookieModal = () => {
        if (window.Cookiebot) {
            window.Cookiebot.renew();
            return;
        }

        dispatch(addNotification({content: "Nie udało się otworzyć okna ustawień cookies, spróbuj później", type: "failure"}));
    };

    return {
        various: [
            {
                title: "Nowe mieszkania",
                url: rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania"})
            },
            {
                title: "Nowe domy",
                url: rpAppLink.offer.listFriendly({friendlySlug: "nowe-domy"})
            },
            {
                title: "Nowe lokale użytkowe",
                url: rpAppLink.offer.listFriendly({friendlySlug: "nowe-lokale-uzytkowe"})
            },
            {
                title: "Apartamenty wakacyjne",
                url: rpAppLink.investmentOffer.investmentCategory.base({category: InvestmentOfferTypeSlug.HOLIDAY})
            },
            {
                title: "Condohotel",
                url: rpAppLink.investmentOffer.investmentCategory.base({category: InvestmentOfferTypeSlug.CONDOHOTEL})
            },
            {
                title: "Luksusowe mieszkania",
                url: buildFriendlyOfferListLink({type: 1, is_luxury: true})
            },
            {
                title: "Luksusowe domy",
                url: buildFriendlyOfferListLink({type: 2, is_luxury: true})
            },
            {
                title: "Promocje",
                url: rpAppLink.promotions.list()
            },
            {
                title: "Deweloperzy",
                url: rpAppLink.vendor.list()
            }
        ],
        cityOffers: [
            {
                title: "Nieruchomości Warszawa",
                url: rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania-i-domy-warszawa"})
            },
            {
                title: "Nieruchomości Kraków",
                url: rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania-i-domy-krakow"})
            },
            {
                title: "Nieruchomości Wrocław",
                url: rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania-i-domy-wroclaw"})
            },
            {
                title: "Nieruchomości Poznań",
                url: rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania-i-domy-poznan"})
            },
            {
                title: "Nieruchomości Gdańsk",
                url: rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania-i-domy-gdansk"})
            },
            {
                title: "Nieruchomości Łódź",
                url: rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania-i-domy-lodz"})
            },
            {
                title: "Nieruchomości Kielce",
                url: rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania-i-domy-kielce"})
            },
            {
                title: "Nieruchomości Lublin",
                url: rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania-i-domy-lublin"})
            },
            {
                title: "Nieruchomości Bydgoszcz",
                url: rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania-i-domy-bydgoszcz"})
            }
        ],
        promotions: [
            {
                title: "Aktualne promocje",
                url: rpAppLink.promotions.listFriendly({friendlySlug: buildFriendlyPromotionSlug({type: PromotionType.OFFER})})
            },
            {
                title: "Dni otwarte",
                url: rpAppLink.promotions.listFriendly({friendlySlug: buildFriendlyPromotionSlug({type: PromotionType.OPENDAYS})})
            },
            {
                title: "Przeceny",
                url: rpAppLink.promotions.listFriendly({friendlySlug: buildFriendlyPromotionSlug({type: PromotionType.DISCOUNT})})
            },
            {
                title: "Oferty last minute",
                url: rpAppLink.promotions.listFriendly({friendlySlug: buildFriendlyPromotionSlug({type: PromotionType.LASTMINUTE})})
            },
            {
                title: "Przedsprzedaż",
                url: rpAppLink.promotions.listFriendly({friendlySlug: buildFriendlyPromotionSlug({type: PromotionType.PRESALE})})
            }
        ],
        info: [
            {
                title: "O nas",
                url: propertyGroupLink,
                rel: "nofollow"
            },
            {
                title: "Kariera",
                url: opportunityListPGLink,
                rel: "nofollow"
            },
            {
                title: "Nasi Partnerzy",
                url: rpAppLink.partners.list()
            },
            {
                title: "Polityka Prywatności",
                url: rpAppLink.rodoTerms.base(),
                target: "blank"
            },
            {
                title: "Regulamin",
                url: rpAppLink.termsOfService.base(),
                target: "blank"
            },
            {
                title: "Mapa strony",
                url: rpAppLink.siteMap.base()
            },
            {
                title: "Ustawienia cookies",
                onClick: openCookieModal
            },
            {
                title: `Konkurs „Wygraj 100 000 zł na dobry start!"`,
                url: rpAppLink.external.competition,
                target: "blank"
            }
        ],
        cooperation: [
            {
                title: "Dla architekta",
                url: rpAppLink.contractor.base()
            },
            {
                title: "Gethome",
                titleAttribute: "nieruchomości na sprzedaż i wynajem",
                url: rpAppLink.external.getHome,
                target: "blank"
            },
            {
                title: "Sprzedaj grunt",
                url: rpAppLink.buylando(),
                target: "blank"
            }
        ],
        topArticles: []
    };
}
