import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, reduceResponseField, reduceSpreadResponse, RequestState} from "@pg-mono/request-state";

import {fetchDetailPoiTypes} from "../actions/fetch_detail_poi";
import {fetchPoiRoutesTypes, IOsmPoiRoutes} from "../actions/fetch_osm_poi_routes";
import {fetchPoisRoutesGroupedTypes, IOSMPoisRoutesGrouped} from "../actions/fetch_osm_pois_routes_grouped";
import {fetchPublicTransportRouteTypes} from "../actions/fetch_public_transport_route";
import {IPoiAround} from "../types/IPoiAround";
import {IPublicTransportRouteWithStops} from "../types/IPublicTransportRouteWithStops";

export interface IPoiStore {
    poisRequest: RequestState;
    poisRoutesGroupedRequest: RequestState;
    poisRoutesGrouped: IOSMPoisRoutesGrouped | null;
    poisRoutes: IOsmPoiRoutes | null;
    poiRoute: Record<string, IPublicTransportRouteWithStops> | null;
    requestedArea: IPoiAround | null;
}

export const poiReducer = combineReducers({
    poisRequest: reduceRequestState(fetchDetailPoiTypes),
    requestedArea: reduceResponseField(fetchDetailPoiTypes, "requestedArea"),
    poisRoutesGrouped: reduceResponse(fetchPoisRoutesGroupedTypes),
    poisRoutesGroupedRequest: reduceRequestState(fetchPoisRoutesGroupedTypes),
    poisRoutes: reduceSpreadResponse(fetchPoiRoutesTypes),
    poiRoute: reduceSpreadResponse(fetchPublicTransportRouteTypes)
});
