import {OfferMetaRobots} from "../../constants/offer_meta_robots";
import {OfferType} from "../../types/OfferType";
import {IOfferListingSeoRuleParams} from "./constants";
import {getCustomOfferCanonical} from "./get_custom_offer_canonical";
import {doesExistAnyFilterBesidesAllowed, doesFilterContainsNonListedValues, shouldHaveActiveFilters} from "./offer_listing_canonical_and_robots_helpers";

export const createCanonicalAndRobotsCommonAreaFilterRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () => shouldHaveActiveFilters(params.filters, ["area_0"]),
    robots: OfferMetaRobots.NOINDEX,
    canonical: () => getCustomOfferCanonical(params)
});

export const ALLOWED_FLAT_AREA1_VALUES = ["25", "30", "35", "40", "50", "55", "60", "70", "80", "90", "100", "120"];
export const createCanonicalAndRobotsFlatAreaFilterRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () =>
        params.offerType === OfferType.FLAT &&
        shouldHaveActiveFilters(params.filters, ["area_1"]) &&
        (doesExistAnyFilterBesidesAllowed(params.filters, ["area_1"]) || doesFilterContainsNonListedValues(params.filters.area_1, ALLOWED_FLAT_AREA1_VALUES)),
    robots: OfferMetaRobots.NOINDEX,
    canonical: () => getCustomOfferCanonical(params)
});

export const ALLOWED_HOUSE_AREA1_VALUES = ["70", "75", "80", "85", "90", "100", "110", "120", "130", "140", "150", "300"];
export const createCanonicalAndRobotsHouseAreaFilterRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () =>
        params.offerType === OfferType.HOUSE &&
        shouldHaveActiveFilters(params.filters, ["area_1"]) &&
        (doesExistAnyFilterBesidesAllowed(params.filters, ["area_1"]) || doesFilterContainsNonListedValues(params.filters.area_1, ALLOWED_HOUSE_AREA1_VALUES)),
    robots: OfferMetaRobots.NOINDEX,
    canonical: () => getCustomOfferCanonical(params)
});
