import type {BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import qs from "query-string";

const YSLAB_API_URL = "https://api.resimo.pl/api/v1/ops/rp/";

const yslabBaseQuery = fetchBaseQuery({
    paramsSerializer: qs.stringify
});

const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    const endpointUrl = typeof args === "string" ? args : args.url;

    const adjustedArgs = typeof args === "string" ? `${YSLAB_API_URL}${endpointUrl}` : {...args, url: `${YSLAB_API_URL}${endpointUrl}`};

    return yslabBaseQuery(adjustedArgs, api, extraOptions);
};

export const yslabApi = createApi({
    reducerPath: "yslabApi",
    baseQuery,
    endpoints: () => ({})
});
