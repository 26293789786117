import {IFetchContext} from "@pg-mono/data-fetcher";
import {IMpRegion} from "@pg-mono/rp-api-routes";
import {IModularHouseOfferParams} from "@pg-mono/rp-routes";
import {areaFormat} from "@pg-mono/string-utils";

import {mpApi} from "../../app/api/mp_api";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {rpEnable404ResponseState} from "../../errors/actions/rp_enable_404_response_state";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";
import {getMarketplaceRegionListApi} from "../../region/api/get_marketplace_region_list";
import {getRegionDetailApi} from "../../region/api/get_region_detail";
import {IAppDispatch} from "../../store/store";
import {isRtkqError} from "../../store/utils/is_rtkq_error";
import {getModularHousesOfferDetailApi} from "../api/get_modular_houses_offer_detail";

export const modularHousesOfferDetailViewAction =
    (ctx: IFetchContext<IRPRequestMeta, IModularHouseOfferParams>) => async (dispatch: IAppDispatch, _getState: () => IRPStore) => {
        const {offerId} = ctx.match.params;

        const modularHouseOffer = await dispatch(getModularHousesOfferDetailApi.endpoints.getModularHousesOfferDetail.initiate({offerId: parseInt(offerId)}));

        if ((modularHouseOffer.isError && isRtkqError(modularHouseOffer.error) && modularHouseOffer.error.status === 404) || !modularHouseOffer.data) {
            await dispatch(rpEnable404ResponseState(ctx.route.pathname));
            return false;
        }

        const mhOffer = modularHouseOffer.data;
        const mpRegionsRes = await dispatch(getMarketplaceRegionListApi.endpoints.getModularHousesRegionList.initiate()).unwrap();
        const contractorRegion =
            mpRegionsRes && mhOffer && mhOffer.contractor.regions.length === 1
                ? mpRegionsRes.results.find((mpRegions) => mpRegions.id === mhOffer.contractor.regions[0])
                : null;

        const regionId = contractorRegion && (contractorRegion as IMpRegion).remote_id;
        if (regionId) {
            await dispatch(getRegionDetailApi.endpoints.getRegionDetail.initiate({regionId}));
        }
        await Promise.all(dispatch(mpApi.util.getRunningQueriesThunk()));

        const metaStringsBase = `Dom modułowy ${mhOffer.name} ${mhOffer.contractor.name}`;
        const areaString = mhOffer.area ? ` - ${areaFormat(parseFloat(mhOffer.area), {precision: 2})}` : "";

        dispatch(
            updateMetaData({
                title: `${metaStringsBase} - rynekpierwotny.pl`,
                description: `${metaStringsBase}${areaString}. Sprawdź gotowe domy modułowe - kup projekt już dziś!`,
                pathname: ctx.route.pathname
            })
        );
    };
