import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: {requestHeaders: Record<string, unknown>} = {
    requestHeaders: {}
};

export const headersSlice = createSlice({
    name: "headers",
    initialState,
    reducers: {
        appendRequestHeaders: (state, action: PayloadAction<Record<string, string | number>>) => {
            state.requestHeaders = {...state.requestHeaders, ...action.payload};
        },
        clearHeadersSlice: () => initialState
    }
});

export const {appendRequestHeaders, clearHeadersSlice} = headersSlice.actions;
