import {IFetchContext} from "@pg-mono/data-fetcher";
import {pick, removeAdditionalSpaces} from "@pg-mono/nodash";
import {safelyParsePage} from "@pg-mono/string-utils/";

import {rpCommonUrl} from "../../../app/read_rp_environment_variables";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {descriptionOfferList} from "../../../meta_data/page_description/offer_list";
import {titleOfferList} from "../../../meta_data/page_title/offer_list";
import {generateMetaRegionName} from "../../../meta_data/utils/generate_meta_region_name";
import {generatePrevNextMetaLinks} from "../../../meta_data/utils/generate_prev_next_links";
import {trimAggregationFromRegion} from "../../../meta_data/utils/trim_aggregation_from_region";
import {parseQueryParamToInt} from "../../../utils/latest_query";
import {offerFilterKeys} from "../../helpers/seo/constants";
import {OFFER_LIST_PAGE_SIZE} from "../constants/offer_list";
import {OfferListSubType} from "../types/OfferListSubType";
import {getOfferListDescriptionException} from "../utils/get_offer_list_description_exception";
import {getOfferListsRobotsCanonicalMeta} from "../utils/get_offer_lists_robots_canonical_meta";

export const updateOfferListMetaData =
    (ctx: IFetchContext<IRPRequestMeta, {offerListSubType?: OfferListSubType; friendlySlug: string; offerListSubFilter?: string}>) =>
    async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
        const state = getState();
        const {selectedRegions, search} = state.offerList;
        const {count, latestQuery, sold_offers_count, soon_finished_offers_count, propertiesCount} = getState().offerList.list;
        const regions = selectedRegions.length > 0 ? selectedRegions : undefined;
        const filterOptions = {
            is_luxury: search != null && search.is_luxury,
            holiday_location: search != null && search.holiday_location,
            price_lower_than_average: Boolean(latestQuery.price_lower_than_average),
            construction_end_date: latestQuery.construction_end_date as string
        };
        const {page, ...restQuery} = ctx.route.query;

        const {offerListSubType, offerListSubFilter} = ctx.match.params;
        const shouldUseMetaTagsFromApi = Object.values(ctx.route.query || {}).length === 0 && !offerListSubType && !offerListSubFilter;

        const parsedPage = safelyParsePage(page); // right now we do not set unique meta data

        const data = pick(latestQuery, offerFilterKeys);

        const convertedQuery = {
            ...data,
            sort: latestQuery.sort,
            price_lower_than_average: Boolean(latestQuery.price_lower_than_average),
            is_luxury: Boolean(latestQuery.is_luxury),
            type: parseQueryParamToInt(latestQuery.type)
        };

        const title =
            search?.show_meta_fields && search?.meta_title
                ? search?.meta_title
                : removeAdditionalSpaces(
                      titleOfferList(
                          {
                              regionName: regions && regions[0] && generateMetaRegionName(regions[0]),
                              ...convertedQuery,
                              regions
                          },
                          parsedPage,
                          filterOptions,
                          offerListSubFilter
                      )
                  );

        const description =
            search?.show_meta_fields && search?.meta_description
                ? search?.meta_description
                : removeAdditionalSpaces(
                      descriptionOfferList({
                          regions,
                          propertiesCount,
                          ...convertedQuery,
                          page: parsedPage,
                          offerType: convertedQuery.type,
                          offerListSubType,
                          offerListSubFilter
                      })
                  );

        // calculate `prev` and `next` links
        const maxStandardListPage = count > 0 ? Math.ceil(count / OFFER_LIST_PAGE_SIZE) : 1;
        const maxClosedListPage = sold_offers_count > 0 ? Math.ceil(sold_offers_count / OFFER_LIST_PAGE_SIZE) : 1;
        const maxPage = maxStandardListPage + maxClosedListPage - 1;
        const hostAndPathname = `${rpCommonUrl}${ctx.route.pathname}`;
        const {prev, next} = generatePrevNextMetaLinks(parsedPage, maxPage, hostAndPathname, restQuery);

        // other meta

        const {robots, canonical: customCanonicalPath} = getOfferListsRobotsCanonicalMeta({
            ctx,
            latestQuery,
            offersCount: count,
            hasSeoDescription: Boolean(search?.seo_description),
            soldOffersCount: sold_offers_count,
            soonFinishedOffersCount: soon_finished_offers_count,
            regions
        });

        const canonicalFromApi = shouldUseMetaTagsFromApi ? search?.canonical_url ?? null : null;

        const metaDataOptions = {
            title,
            description: getOfferListDescriptionException(ctx.route.pathname) ? "" : description,
            pathname: ctx.route.pathname,
            pageNumber: parsedPage,
            customCanonicalPath: canonicalFromApi || trimAggregationFromRegion(customCanonicalPath || ctx.route.pathname),
            prev,
            next,
            robots
        };

        dispatch(updateMetaData(metaDataOptions));

        return true;
    };
